import React, { useEffect } from "react";
// import { FormattedMessage, useIntl } from "react-intl";
// import classNames from "classnames";
import configs from "../../utils/configs";
// import { CreateRoomButton } from "./CreateRoomButton";
// import { PWAButton } from "./PWAButton";
// import { useFavoriteRooms } from "./useFavoriteRooms";
import { usePublicRooms } from "./usePublicRooms";
// import styles from "./HomePage.scss";
// import { AuthContext } from "../auth/AuthContext";
// import { createAndRedirectToNewHub } from "../../utils/phoenix-utils";
// import { MediaGrid } from "../room/MediaGrid";
// import { MediaTile } from "../room/MediaTiles";
// import { PageContainer } from "../layout/PageContainer";
// import { scaledThumbnailUrlFor } from "../../utils/media-url-utils";
// import { Column } from "../layout/Column";
// import { Container } from "../layout/Container";
// import { SocialBar } from "../home/SocialBar";
// import { SignInButton } from "./SignInButton";
// import { AppLogo } from "../misc/AppLogo";
// import { isHmc } from "../../utils/isHmc";
// import maskEmail from "../../utils/mask-email";

export function HomePage() {
  //   const auth = useContext(AuthContext);
  //   const intl = useIntl();

  //   const { results: favoriteRooms } = useFavoriteRooms();
  const { results: publicRooms } = usePublicRooms();
  const firstRoomLinks = ["A8netia", "z7MT6JA", "4G5ALPR", "AVkkCCi", "qLseRnQ", "ytTY3Dc", "z6ngbTP"];
  const ignoredRooms = ["ayodhya"];

  //   const sortedFavoriteRooms = Array.from(favoriteRooms).sort((a, b) => b.member_count - a.member_count);
  const sortedPublicRooms = Array.from(publicRooms).sort((a, b) => b.member_count - a.member_count);
  //   const wrapInBold = chunk => <b>{chunk}</b>;
  useEffect(() => {
    const qs = new URLSearchParams(location.search);

    // Support legacy sign in urls.
    if (qs.has("sign_in")) {
      const redirectUrl = new URL("/signin", window.location);
      redirectUrl.search = location.search;
      window.location = redirectUrl;
    } else if (qs.has("auth_topic")) {
      const redirectUrl = new URL("/verify", window.location);
      redirectUrl.search = location.search;
      window.location = redirectUrl;
    }

    if (qs.has("new")) {
      qs.delete("new");
      //   createAndRedirectToNewHub(null, null, true, qs);
    }
  }, []);

  useEffect(() => {
    if (!publicRooms) return;
    console.log("these are the public rooms", publicRooms);
    // if (configs.isAdmin()) return;
    const publicAllRoomIds = sortedPublicRooms.map(room => room.id);
    if (sortedPublicRooms == null || sortedPublicRooms.length == 0) return;
    let sumOfMember;
    for (let i = 0; i < sortedPublicRooms.length; i++) {
      if (!ignoredRooms.includes(sortedPublicRooms[i]["id"]) && firstRoomLinks.includes(sortedPublicRooms[i]["id"])) {
        const firstRoomIndex = firstRoomLinks.findIndex(room => room === sortedPublicRooms[i]["id"]);
        const firstRoomId = firstRoomLinks[firstRoomIndex];
        const firstRoomIndexInAllRooms = publicAllRoomIds.findIndex(room => room === firstRoomId);
        sumOfMember =
          parseInt(sortedPublicRooms[firstRoomIndexInAllRooms]["member_count"]) +
          parseInt(sortedPublicRooms[firstRoomIndexInAllRooms]["lobby_count"]);
        if (sumOfMember < 10) {
          //room changing here
          //   alert("w'erein");
          console.log(sortedPublicRooms[i]["id"], "sortedPublicRooms here");
          const metaverseLink = sortedPublicRooms[i]["url"];
          //   const metaverseLink = metaverseLinkFromUrl(src);
          const qs = new URLSearchParams(location.search);
          window.open(metaverseLink + "?avatarId=" + qs.get("avatarId") + "&name=" + qs.get("name"), "_self");
          break;
        }
        // when everything else fails
        // if (i == sortedPublicRooms.length - 1) {
        //   const randomInt = Math.floor(Math.random() * sortedPublicRooms.length - 1);
        // }
      }
    }
    console.log(sortedPublicRooms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicRooms]);

  //   const canCreateRooms = !configs.feature("disable_room_creation") || auth.isAdmin;
  //   const email = auth.email;
  return (
    <>
      <img
        src={configs.image("home_background")}
        style={{ width: "100%", height: "100vh", objectFit: "cover", position: "fixed", top: 0, left: 0 }}
      />{" "}
    </>
  );
}
